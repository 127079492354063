.homeVideo {
    width: 100%;
    height: calc(100vh - 2.5rem);
    z-index: 10;
    position: relative;
    overflow: hidden;
    & > .videoContainer {
        width: 100%;
        height: 100%;
        z-index: -100;
        & > video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    & > .homeVideoTextOverlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 50;
        width: 100%;
        height: 100%;
        position: absolute;
        color: white;
        text-shadow: 1px 1px black;
        & > .imprecoContainer {
            opacity: 0;
            transition: opacity 0.5s;
            position: absolute;
            right: 3rem;
            top: 8rem;
        }
        & > .shown {
            opacity: 1;
        }
        & > h2 {
            font-family: 'Roboto';
            font-size: 1.4rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.35;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin: 0.5rem;
        }
        & > h1 {
            font-family: 'Roboto';
            font-size: 3rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.35;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin: 0.5rem;
        }
    }
}

.homePage {
    width: 100%;
    & > .welcomeBanner {
        width: 100%;
        height: 7.5rem;
        background-image: linear-gradient(95deg, #4ca6c1 13%, #265361 85%);
        font-size: 1.5rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }
    & > .HPContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1440px;
        margin: 0 auto;
        & > .imageRow {
            display: grid;
            justify-content: space-around;
            grid-template-columns: repeat(4, calc(300px + 2rem));
            width: 100%;
            max-width: 85vw;
            padding: 7rem 0;
            @media only screen and (max-width: 1330px) {
                grid-template-columns: repeat(2, calc(300px + 2rem));
            }
            @media only screen and (max-width: 680px) {
                grid-template-columns: repeat(1, minmax(100%, calc(300px + 2rem)));
            }
            & > * {
                padding: 1rem;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                justify-content: space-between;
                & > .iwlc1 {
                    width: 100%;
                    & > img {
                        width: 100%;
                    }
                }
                & > .invertedColorsButton {
                    justify-self: flex-end;
                }
            }
        }
    }
    & > .divider {
        width: 100%;
        height: 2rem;
        opacity: 0.6;
        background-image: linear-gradient(90deg, rgba(0, 194, 255, 1) 0%, rgba(76, 166, 193, 1) 100%);
    }
    & > .parallaxWrapper {
        position: relative;
        & > .parallaxOverlay {
            position: absolute;
            display: flex;
            bottom: 0;
            margin-bottom: 4.3rem;
            width: 100%;
            flex-direction: row;
            justify-content: center;
            & > * {
                margin: 1.25rem 5rem;
            }
        }
    }
    & > .HPDidYouSeeContainer {
        background: rgb(64, 111, 125);
        background: linear-gradient(90deg, rgba(64, 111, 125, 1) 0%, rgba(76, 166, 193, 1) 100%);
        & > .HPDYSTC-grid {
            width: 100%;
            height: 100%;
            max-width: 1440px;
            box-sizing: border-box;
            margin: 0 auto;
            padding: 5rem 10rem;
            display: grid;
            grid-template-columns: 65% 35%;
            & > img {
                justify-self: center;
                align-self: flex-start;
                object-fit: contain;
            }
            & > .HPDYSTC-info {
                padding: 0 5.75rem 0 0;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                & > p {
                    color: white;
                }
                & > .invertedColorsButton {
                    margin: 0;
                }
                & > h2 {
                    margin-top: 0;
                }
            }
        }
    }
    .HPQuiz {
        & > .HPELHeading {
            font-size: 1.25rem;
            color: var(--primaryColor);
            margin: 0 0 1.5rem 0;
            text-align: center;
        }
        & > p {
            text-align: center;
            max-width: 95vw;
        }
    }
    .HPMap {
        width: 100%;
        & > div {
            width: 100%;
        }
    }
}
.gmap_canvas {
    height: 500px;
}

@media only screen and (max-width: 1179px) {
    .homePage {
        display: flex;
        flex-direction: column;
    }
    .homeVideoTextOverlay {
        & > .imprecoContainer {
            position: static !important;
            max-width: 80vw;
        }
        & > h1 {
            font-size: 2rem !important;
        }
        & > h2 {
            font-size: 1.15rem !important;
        }
    }
    .HPDYSTC-grid {
        display: flex !important;
        flex-direction: column !important;
        padding: 2rem !important;
        & > div {
            padding: 0 !important;
        }
        & > img {
            max-width: 100%;
            margin-top: 2rem !important;
        }
    }
    .imageWithLinkContainer {
        & > p {
            text-align: center;
        }
    }
    .HPContent {
        & > img {
            width: 100%;
            box-sizing: border-box;
            padding: 2rem !important;
        }
        & > .imageRow {
            padding-top: 3rem !important;
            padding-bottom: 3rem !important;
        }
    }
    .HPQuiz {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    .parallaxWrapper {
        justify-self: center;
        align-self: center;
        & > .parallaxOverlay {
            margin-bottom: 0.5rem !important;
            & > .invertedColorsButton {
                margin: 0.8rem 1.5rem !important;
            }
        }
    }
    .welcomeBanner {
        font-size: 1.3rem !important;
        height: 5rem;
    }
}
