.noPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: center no-repeat cover;
    margin-top: 0 !important;
    max-width: unset !important;
    height: 50vw;
    min-height: 50vh;
    width: 100vw;
    & > .messageContainer {
        height: 100px;
        background-color: #00000033;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .textWithMargin {
            display: inline-block;
            width: auto;
            text-align: center;
            & > * {
                display: inline-block;
                font-weight: 700;
                color: white;
                font-size: 1.25rem;
                margin: 0;
            }
            & > .noPageLink {
                text-decoration: underline;
                cursor: pointer;
                margin-left: 0.5rem;
                color: var(--primaryColorLight);
            }
        }
    }
}
