.beaches {
    & > .imageRow {
        display: grid;
        width: 100%;
        margin: calc(3rem - 1em) 0 0 0;
        max-width: 95vw;
        justify-content: space-around;
        grid-template-columns: repeat(auto-fill, calc(500px + 2rem));
        @media only screen and (max-width: 1180px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 0;
        }
        & > div,
        img {
            margin: 1rem;
            height: unset;
            max-width: 90vw;
            & > img {
                margin: 0;
                height: unset;
            }
        }
    }
}
