.divingBlueBackground {
    background-color: #e8faff;
    box-sizing: border-box;
    padding: 1.6rem;
    & > * {
        margin: 0;
    }
}
.divingContentUnit {
    margin-bottom: 1.5rem;
    width: 100%;
    @media only screen and (max-width: 1000px) {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .diving {
        & > p {
            margin-bottom: 0;
        }
        & > img {
            width: 100%;
        }
        & > .divingContentUnit > .pictureRight > img,
        .pictureLeft > img {
            margin-top: 1rem;
        }
    }
    .divingBlueBackground {
        margin: 0 0 0 0 !important;
    }
}
