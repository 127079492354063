.filterSelection {
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    margin: 1rem auto;
    max-width: 1000px;
    box-sizing: border-box;
    & > .seenThingSelection {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        & > .filterCheckBoxItem {
            flex-grow: 1;
            border-radius: 10px;
            padding: 5px;
            justify-content: center;
            margin: 0.2rem 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #144250bb;
            border: 2px solid #144250bb;
            background: white;
            cursor: pointer;
            &.selected {
                color: white;
                background: #144250bb;
                font-weight: bold;
            }
            &:hover {
                color: white;
                background: #144250bb;
            }
            & > p {
                margin: 0;
                color: inherit;
                font-weight: inherit;
            }
        }
    }
}
.colorLegend {
    width: 15px;
    height: 15px;
    margin: 5px;
    border-radius: 3px;
    border: 1px solid black;
}
