.contact {
    margin-bottom: 5.5rem;
    align-items: flex-start;
    & > h2 {
        text-align: left;
    }
    & > p {
        margin: 0;
    }
}
