#newsOverview {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  margin-bottom: 60px;
  gap: 60px;
  width: 100%;

  & hr {
    display: none;
    margin: 20px;

    @media only screen and (max-width: 900px) {
      display: block;
    }
  }

  & .card {
    color: black;
    transition: 200ms ease;

    &_image {
      display: flex;
      max-height: 300px;
      width: auto;
      overflow: hidden;

      & img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    &_container {
      height: 700px;
      position: relative;
      overflow: hidden;
      padding: 20px;
    }

    &_overlay {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background-image: linear-gradient(0deg, white 25%, transparent 100%);
      position: absolute;
      height: 300px;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &_text {
      margin: 40px 0;
      p {
        margin: 10px 0 !important;
      }
    }

    &_date {
      margin: 20px 0;
    }

    &:hover {
      background-color: rgba(224, 224, 224, 0.406);

      .card_overlay {
        background-image: linear-gradient(
          0deg,
          rgb(224, 224, 224) 25%,
          transparent 100%
        );
      }
    }
  }
}
