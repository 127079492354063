.imageWithLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > p {
      margin: 1rem;
      font-weight: bold;
      text-align: center;
    }
    & > div {
      font-weight: normal;
    }
  }
}
