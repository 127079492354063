#newsPage {
  margin-bottom: 120px;

  & .page {
    color: black;

    & h2 {
      margin: 40px 0;
    }

    &_image {
      display: flex;
      height: 100%;
      width: 100%;

      & > img {
        width: 100%;
        height: 100%;
      }
    }

    &_text {
      p {
        margin: 10px 0 !important;
      }
    }

    &_date {
      align-self: flex-end;
      margin: 20px;
    }
  }
}
