.VG-container {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 350px);
    & > .VG-videoThumbContainer {
        position: relative;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        & > .VG-video {
            width: 100%;
            object-fit: cover;
            padding: 5px;
            box-sizing: border-box;
            cursor: pointer;
            &:hover {
                padding: 0;
                -webkit-box-shadow: 0px 6px 23px 9px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 6px 23px 9px rgba(0, 0, 0, 0.75);
                box-shadow: 0px 6px 23px 9px rgba(0, 0, 0, 0.75);
            }
        }
        & > .VG-playVideoPictureOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: no-repeat center;
            background-size: 70px 70px;
            pointer-events: none;
        }
    }
    & > .VG-maximizedVideoContainer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        padding: $toolBarHeight + 7.5rem 0 1.25rem 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        z-index: 120;
        & > .VG-maximizedVideo {
            object-fit: contain;
            max-height: 70vh;
            max-width: 80vw;
            margin: 10px;
            & > .VG-maximizedVideoSource {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        & > .VG-x-button {
            position: fixed;
            width: 100px;
            height: 100px;
            top: 25vh;
            right: 10px;
            color: white;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 1179px) {
    .VG-container {
        display: flex !important;
        flex-direction: column;
    }
    .VG-maximizedVideoContainer {
        padding: 0 !important;
        & > .VG-maximizedVideo {
            max-width: unset !important;
            max-height: unset !important;
            width: 100vw;
            margin-top: 2.5rem !important;
            height: calc(100vh - 2.5rem);
        }
    }
    .VG-x-button {
        font-size: 0.7rem;
        right: 0.6rem !important;
        top: 2.5rem !important;
        height: unset !important;
        width: unset !important;
    }
}

#html5-videos {
    width: 100%;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    & > li {
        width: 100%;
        height: 100%;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding: 5px;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(1.05);
            }
        }
    }
}
