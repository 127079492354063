.natHerContent {
    max-width: 90vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > img {
        margin: 30px 0;
        width: 100%;
    }
    & > .natHerImages {
        width: 100%;
        max-width: 90vw;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 370px);
        align-items: center;
        margin: 2rem 0 0 0;
        @media only screen and (max-width: 1240px) {
            grid-template-columns: repeat(auto-fill, 32%);
        }
        @media only screen and (max-width: 900px) {
            display: flex;
            flex-direction: column;
            width: 90vw;
        }
        & > * {
            padding: 0;
            width: 100%;
            height: 100%;
            max-width: 370px;
            margin: 0 0 1rem 0;
        }
    }
}

@media only screen and (max-width: 1179px) {
    .naturalHeritage {
        & > h2 {
            text-align: center;
        }
    }
}
