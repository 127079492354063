.geoPaleoImages {
    display: grid;
    width: 100%;
    max-width: 95vw;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 370px);
    align-items: center;
    @media only screen and (max-width: 1240px) {
        grid-template-columns: repeat(auto-fill, 32%);
    }
    @media only screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        width: 90vw;
    }
    & > div {
        max-width: 370px;
    }
    & > .imageWithCaption {
        margin-bottom: 1rem;
        height: 100%;
    }
}
.geoPaleo {
    & > div {
        width: 100%;
        max-width: 90vw;
    }
}
.GPHSubtitle {
    margin: 3rem 0 1rem;
    @media only screen and (max-width: 1240px) {
        margin: 2rem 0 1rem;
    }
}
