.pageIsLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 8rem;
    padding: 2rem;
}

@media only screen and (max-width: 1179px) {
    .pageIsLoading {
        width: 100vw;
        max-width: 100%;
        box-sizing: border-box;
        margin-top: 5.8rem;
    }
}
