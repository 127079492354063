.DYSTModalContainer {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1111;
    display: flex;
    justify-content: center;
    align-items: center;
    & > .DYSTModal {
        max-height: 95%;
        width: 60vw;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Roboto';
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.05px;
        text-align: left;
        padding: 4rem;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.53);
        border: solid 1px #4ca6c1;
        background-color: #ffffff;
        & > .divider {
            width: 8vw;
            height: 0;
            margin: calc(2rem - 1em) 0;
            border-top: 1px solid #4ca6c1;
        }
        & > .closeDYSTModal {
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            cursor: pointer;
        }
        & > h1 {
            font-size: 1.3rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #1a1a1a;
        }
    }
}

@media only screen and (max-width: 1179px) {
    .DYSTModal {
        width: 90% !important;
        box-sizing: border-box;
        padding: 2rem 1rem 1rem 1rem !important;
        height: 95%;
        display: grid !important;
        align-items: center !important;
        justify-content: center !important;
        grid-template-rows: 15% 0px 30% 55%;
        & > .DYSTModalImageContainer {
            width: 100%;
            height: 100%;
            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                max-width: unset !important;
                max-height: unset !important;
            }
        }
        & > h1 {
            text-align: center !important;
            font-size: 1.1rem !important;
            font-weight: bold !important;
        }
        & > p {
            height: calc(100% - 1rem);
            padding: 1rem 0;
            margin-top: 1rem;
            box-sizing: border-box;
            overflow-y: scroll;
        }
        & > .divider {
            width: 25% !important;
            margin: auto !important;
        }
        & > .closeDYSTModal {
            top: 1rem !important;
            right: 1rem !important;
        }
    }
}
