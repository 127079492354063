.dropDown {
    display: flex;
    flex-direction: column;
    & > .dropDownContainer {
        position: relative;
    }
}
.ddchild {
    position: absolute;
    left: 100%;
    top: 0;
    opacity: 0;
}
