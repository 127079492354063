.publications {
    margin-bottom: 5.5rem;
    & > p {
        text-align: start;
        width: 100%;
    }
    & > h1 {
        width: 100%;
        text-align: left;
    }
    & > .publicationsList {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-sizing: border-box;
    }
    & > ul > li {
        & > a {
            background-color: var(--primaryColor);
            color: white;
            padding: 0.3rem;
            text-decoration: none;
            line-height: 2rem;
        }
        & > .divider {
            width: 30vw;
            height: 0;
            margin: 0.2rem 0 calc(3rem - 1em) 0;
            border-top: 1px solid #4ca6c1;
        }
    }
}
