.SFALImages {
    display: grid;
    width: 100%;
    margin: 3rem 0;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 550px);
    & > div,
    img {
        margin: 1rem 0;
        & > img {
            margin: 0;
        }
    }
}
.SFALText {
    & > h2 {
        margin-top: 0;
    }
}
@media only screen and (max-width: 1240px) {
    .SFALImages {
        display: flex;
        flex-direction: column;
        width: 550px;
        grid-template-columns: unset;
        & > * {
            margin: 1rem 0 !important;
        }
    }
}
@media only screen and (max-width: 680px) {
    .SFALImages {
        width: 100%;
        & > * {
            width: 100%;
            & > * {
                width: 100%;
            }
        }
    }
}
.SFImageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
