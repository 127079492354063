.toolBar {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    height: $toolBarHeight;
    background: rgb(80, 80, 80);
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 201;
    justify-content: flex-end;
    & > .toolBarButton {
        margin: 5px 15px;
        color: white;
        text-decoration: none;
    }
}

@media only screen and (max-width: 1179px) {
    .toolBar {
        & > * {
            font-size: 0.6rem !important;
            margin: 0.3rem 0rem 0.3rem 0.6rem !important;
            & > * {
                font-size: 0.6rem !important;
                margin-right: 0 !important;
            }
        }
    }
}
