.DYSTCustomHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > p {
        text-align: center;
    }
    & > h3 {
        font-size: 1.45rem;
    }
}
.DYSTGrid {
    display: grid;
    height: 100%;
    width: calc(100vw - 6rem);
    justify-content: center;
    max-width: calc(1440px + 2rem);
    grid-template-columns: repeat(auto-fill, 433px);
    & > .imageWrapper {
        margin: 0.5rem;
        cursor: pointer;
    }
}
.DYSTFormInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0 2rem 0;
    & > p,
    h2 {
        text-align: center;
    }
}
@media only screen and (max-width: 1179px) {
    .DYSTGrid {
        grid-template-columns: minmax(10px, 430px);
        & > * {
            margin: 1rem 0rem !important;
        }
    }
}
