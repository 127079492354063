.PG-container {
    width: 100%;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fill, 280px);
    & > .PG-photo {
        width: 270px;
        height: 270px;
        object-fit: cover;
        padding: 5px;
        box-sizing: border-box;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
            padding: 0;
        }
        & > img {
            width: 100%;
            height: 100%;
        }
    }
    & > .PG-maximizedPictureContainer {
        position: fixed;
        top: 0;
        z-index: 150;
        left: 0;
        width: 100vw !important;
        height: 100vh !important;
        padding: 3.5rem 1rem 1rem 1rem;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        & > .PG-maximizedPicture {
            width: 80vw;
            height: 100%;
            object-fit: contain;
            margin: 10px;
        }
        & > .PG-leftArrow {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 1rem;
            height: 100px;
            color: white;
            cursor: pointer;
        }
        & > .PG-rightArrow {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 2rem;
            color: white;
            cursor: pointer;
        }
        & > .PG-x-button {
            position: fixed;
            margin: 3rem;
            top: 0;
            right: 0;
            color: white;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 1179px) {
    .PG-container {
        width: 90vw;
        grid-template-columns: 1fr 1fr;
        & > * {
            width: 100% !important;
            height: 45vw !important;
            padding: 1rem;
            box-sizing: border-box;
        }
        & > .PG-maximizedPictureContainer {
            width: 100vw !important;
            height: 100vh !important;
            padding-top: 3.5rem !important;
            & > .PG-leftArrow,
            .PG-rightArrow {
                width: 3rem;
                height: 3rem;
                & > * {
                    font-size: 3rem;
                }
            }
            & > img {
                width: 100vw !important;
            }
            & > .PG-x-button {
                font-size: 0.7rem !important;
                margin: 3rem 0.5rem 0 0 !important;
            }
            & > .PG-leftArrow {
                left: 0;
            }

            & > .PG-rightArrow {
                right: 0;
            }
        }
    }
}
