.imageWrapper {
    position: relative;
    & > .DYSTImageBottomShadow {
        position: absolute;
        bottom: 4px;
        left: 0;
        width: 100%;
        height: 25%;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65));
    }
    & > .DYSTImageInfo {
        position: absolute;
        top: 13px;
        right: 13px;
    }
}
