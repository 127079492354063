.culHisImages {
    display: grid;
    width: 100%;
    max-width: 95vw;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 370px);
    margin: 3rem 0 0;
    @media only screen and (max-width: 1240px) {
        grid-template-columns: repeat(auto-fill, 32%);
    }
    @media only screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90vw;
    }
    & > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        max-width: 370px;
        & > img {
            margin-bottom: 10px;
        }
    }
}
.culHistHeritageContent {
    width: 100%;
}
.culHistImage {
    width: 100%;
    max-width: 370px !important;
    margin: 0 auto;
}
