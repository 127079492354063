.hospitality {
  align-items: flex-start;
  margin-bottom: 5.5rem;
}

.hospitality_partners {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;

  @media only screen and (max-width: 915px) {
    flex-direction: column;
    gap: unset;
  }

  & > div {
    flex: 50%;

    @media only screen and (max-width: 915px) {
      flex: none;
    }

    & > a {
      line-height: 2rem;
    }
  }
}
