.touristMap {
    & > h1 {
        @media only screen and (max-width: 1179px) {
            font-size: 1.4rem;
        }
    }
    & > img {
        width: 90vw;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}
