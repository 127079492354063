.imageWithCaption {
    max-width: 100%;
    position: relative;
    & > p {
        font-size: 0.85rem;
        margin: 0;
        max-width: 100%;
        box-sizing: border-box;
    }
    & > img {
        width: 100%;
        object-fit: cover;
    }
    & > .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        z-index: 102;
        margin: 0.5rem;
    }
}

.pictureLeft > div > .IWC-caption {
    margin-left: 0 !important;
}
