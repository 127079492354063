.serviceInfoBullet {
    margin: 5px;
}
.rulesBackground {
    background-color: #e8faff;
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 5rem 2rem 5rem;
    margin: 0;
    max-width: 1180px;
}
.SIImageWithBackground {
    position: relative;
    left: -0.75rem;
    margin: 3.75rem 0 7rem 0;
    .SIImageBackground {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        z-index: -5;
        width: 629px;
        height: 355px;
        opacity: 0.6;
        background: linear-gradient(260deg, rgba(76, 166, 193, 1) 0%, rgba(38, 83, 97, 1) 100%);
    }
}

@media only screen and (max-width: 1179px) {
    .rulesBackground {
        padding: 0 0.5rem 0 0;
        & > ul {
            padding-left: 2rem;
        }
        & > h2 {
            padding: 1rem 0 0 36px;
        }
    }
    .SIImageWithBackground {
        width: 90%;
        left: -0.4rem !important;
        & > * {
            top: 0.8rem !important;
            left: 0.8rem !important;
            width: 100% !important;
            height: 100% !important;
        }
    }
}
