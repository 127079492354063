.footerContainer {
    height: auto;
    padding: 0;
    padding-top: 3rem;
    width: 100%;
    margin: 0 auto;
    background-color: rgb(31, 143, 178);
    background-image: url('./footerBackground.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    & > .footerGrid {
        margin: auto;
        display: grid;
        grid-template-rows: auto 3rem 3.75rem;
        width: 1180px;
        & > .footerInfo {
            width: 100%;
            border-top: 1px solid white;
            display: flex;
            justify-content: space-between;
            font-family: 'Roboto';
            font-size: 0.85rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
            height: 100%;
            align-items: center;
            & > div > span {
                padding: 0 10px;
            }
            & > div > a {
                color: white;
                text-decoration: none;
            }
            & > div > span > a {
                color: white;
                text-decoration: none;
            }
        }
        & > .footerLinks {
            display: grid;
            width: 100%;
            grid-template-columns: 34% 22% 22% 22%;
            & > .footerColumn {
                padding: 0 15px;
                & > .footerSublist {
                    margin-top: 0.5rem;
                    & > a {
                        margin-left: 1rem;
                        @media only screen and (max-width: 800px) {
                            font-size: 1.2rem;
                        }
                    }
                    & > .footerLink:hover {
                        text-shadow: 0px 0px 6px white;
                    }
                }
                & > p {
                    font-family: 'Roboto';
                    font-size: 0.9rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.87;
                    letter-spacing: -0.05px;
                    text-align: left;
                    color: #ffffff;
                }
                & > img {
                    margin: 1em 0;
                    width: 200px;
                }
                & > h3,
                a > h3 {
                    color: white;
                    margin: 0.5em 0;
                    margin-inline-end: 0;
                    padding-bottom: 0;
                    @media only screen and (max-width: 800px) {
                        font-size: 1.4rem;
                        width: 100%;
                        height: 46px;
                        line-height: 46px;
                    }
                }

                & > span,
                a {
                    cursor: pointer;
                    text-decoration: none;
                    font-family: 'Roboto';
                    font-size: 0.9rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    word-wrap: break-word;
                    flex-wrap: wrap;
                    line-height: 1.87;
                    letter-spacing: -0.05px;
                    text-align: left;
                    color: #ffffff;
                    display: block;
                    @media only screen and (max-width: 800px) {
                        font-size: 1.2rem;
                        padding: 0;
                        width: 100%;
                        line-height: 46.5px;
                    }
                }
                & > .footerLink:hover,
                a:hover {
                    text-shadow: 0px 0px 6px white;
                }
            }
        }
    }
}
.fesbFooterIconRow {
    display: flex !important;
    width: 100%;
    justify-content: center;
    & > .sAppIcon {
        text-decoration: none;
        width: unset !important;
        & > i {
            cursor: pointer;
            margin: 8px 16px 8px 0;
            color: #4ca6c1;
            background-color: white;
            height: 32px;
            font-size: 1.25rem;
            width: 32px;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            border-radius: 16px;
            @media only screen and (max-width: 1179px) {
                margin: 8px 12px 12px 0px;
            }
        }
    }
}

@media only screen and (max-width: 1179px) {
    .footerContainer {
        height: unset;
        & > .footerGrid {
            display: flex !important;
            width: 100% !important;
            flex-direction: column !important;
            & > .footerLinks {
                width: 100% !important;
                display: flex !important;
                flex-direction: column !important;
                & > .footerColumn {
                    display: flex;
                    flex-direction: column;
                    & > img {
                        align-self: center;
                    }
                }
            }
            & > .footerInfo {
                flex-direction: column;
                align-items: flex-start;
                box-sizing: border-box;
                padding: 1rem;
                & > div {
                    display: flex;
                    flex-direction: column;
                    & > a {
                        color: white;
                    }
                    & > span {
                        padding: 0 !important;
                    }
                }
            }
        }
    }
}
.logoRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
    justify-content: space-around;
    height: 100px;
    & > a {
        & > * {
            height: 100px;
        }
    }
    @media only screen and (max-width: 1179px) {
        flex-direction: column;
        align-items: center;
        height: auto;
        & > a {
            width: auto !important;
            & > * {
                height: 100px;
            }
        }
    }
}
.SDCountyLink {
    display: grid !important;
    width: 200px !important;
    height: 100px !important;
    grid-template-columns: 86px 110px;
    justify-content: center;
    align-items: center;
    & > .countyTitle {
        align-items: center;
        justify-content: center;
        margin: 0.5rem 0;
        height: auto;
    }
    @media only screen and (max-width: 1179px) {
        width: 100% !important;
        grid-template-columns: 100px calc(90vw - 100px);
        & > .countyTitle {
            margin: 0.5rem;
        }
    }
}
