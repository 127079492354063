* {
  font-display: swap;
  font-family: "Roboto";
}

.page {
  --primaryColor: #3d798b;
  --primaryColorLight: #7ac1d6;
  margin: 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  top: 2.5rem;
  align-items: center;
  flex-direction: column;
}

.topHeading {
  margin-top: 0 !important;
}

.pageContent {
  margin-top: 7.5rem;
}

.pageContentImprecoSpecial {
  margin: 9.5rem 3rem 1rem;
}

.pageContent,
.pageContentImprecoSpecial {
  width: 90%;
  max-width: 1180px;
}

.pictureLeft {
  margin: 0;
  display: grid;
  grid-template-columns: 39% 61%;
}
@media only screen and (max-width: 1000px) {
  .pictureLeft {
    display: flex;
    flex-direction: column-reverse;
  }
}
.pictureLeft > div > p {
  margin: 0 0 1rem 1rem;
}
@media only screen and (max-width: 1000px) {
  .pictureLeft > div > p {
    margin-left: 0;
  }
}
.pictureLeft > img {
  width: 100%;
}

.pictureRight {
  margin: 0;
  display: grid;
  grid-template-columns: 61% 39%;
}
@media only screen and (max-width: 1000px) {
  .pictureRight {
    display: flex;
    flex-direction: column;
  }
}
.pictureRight > div > p {
  margin: 0 1rem 1rem 0;
}
.pictureRight > img,
.pictureRight .imageWithCaption {
  height: auto;
  width: 100%;
}

.noBottomMargin {
  margin-bottom: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

h3 {
  font-size: 1.1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.05px;
  text-align: left;
}

html {
  font-size: 16px;
  overflow-x: hidden;
  width: 100%;
}

#root,
body {
  width: 100%;
}

@media only screen and (max-width: 1179px) {
  .pageContent {
    margin-top: 3.5rem;
  }
}
.toolBar {
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  height: 2.5rem;
  background: rgb(80, 80, 80);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 201;
  justify-content: flex-end;
}
.toolBar > .toolBarButton {
  margin: 5px 15px;
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 1179px) {
  .toolBar > * {
    font-size: 0.6rem !important;
    margin: 0.3rem 0rem 0.3rem 0.6rem !important;
  }
  .toolBar > * > * {
    font-size: 0.6rem !important;
    margin-right: 0 !important;
  }
}
.homeVideo {
  width: 100%;
  height: calc(100vh - 2.5rem);
  z-index: 10;
  position: relative;
  overflow: hidden;
}
.homeVideo > .videoContainer {
  width: 100%;
  height: 100%;
  z-index: -100;
}
.homeVideo > .videoContainer > video,
.homeVideo > .videoContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeVideo > .homeVideoTextOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 50;
  width: 100%;
  height: 100%;
  position: absolute;
  color: white;
  text-shadow: 1px 1px black;
}
.homeVideo > .homeVideoTextOverlay > .imprecoContainer {
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  right: 3rem;
  top: 8rem;
}
.homeVideo > .homeVideoTextOverlay > .shown {
  opacity: 1;
}
.homeVideo > .homeVideoTextOverlay > h2 {
  font-family: "Roboto";
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0.5rem;
}
.homeVideo > .homeVideoTextOverlay > h1 {
  font-family: "Roboto";
  font-size: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0.5rem;
}

.homePage {
  width: 100%;
}
.homePage > .welcomeBanner {
  width: 100%;
  height: 7.5rem;
  background-image: linear-gradient(95deg, #4ca6c1 13%, #265361 85%);
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.homePage > .HPContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}
.homePage > .HPContent > .imageRow {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(4, calc(300px + 2rem));
  width: 100%;
  max-width: 85vw;
  padding: 7rem 0;
}
@media only screen and (max-width: 1330px) {
  .homePage > .HPContent > .imageRow {
    grid-template-columns: repeat(2, calc(300px + 2rem));
  }
}
@media only screen and (max-width: 680px) {
  .homePage > .HPContent > .imageRow {
    grid-template-columns: repeat(1, minmax(100%, calc(300px + 2rem)));
  }
}
.homePage > .HPContent > .imageRow > * {
  padding: 1rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}
.homePage > .HPContent > .imageRow > * > .iwlc1 {
  width: 100%;
}
.homePage > .HPContent > .imageRow > * > .iwlc1 > img {
  width: 100%;
}
.homePage > .HPContent > .imageRow > * > .invertedColorsButton {
  justify-self: flex-end;
}
.homePage > .divider {
  width: 100%;
  height: 2rem;
  opacity: 0.6;
  background-image: linear-gradient(90deg, rgb(0, 194, 255) 0%, rgb(76, 166, 193) 100%);
}
.homePage > .parallaxWrapper {
  position: relative;
}
.homePage > .parallaxWrapper > .parallaxOverlay {
  position: absolute;
  display: flex;
  bottom: 0;
  margin-bottom: 4.3rem;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.homePage > .parallaxWrapper > .parallaxOverlay > * {
  margin: 1.25rem 5rem;
}
.homePage > .HPDidYouSeeContainer {
  background: rgb(64, 111, 125);
  background: linear-gradient(90deg, rgb(64, 111, 125) 0%, rgb(76, 166, 193) 100%);
}
.homePage > .HPDidYouSeeContainer > .HPDYSTC-grid {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5rem 10rem;
  display: grid;
  grid-template-columns: 65% 35%;
}
.homePage > .HPDidYouSeeContainer > .HPDYSTC-grid > img {
  justify-self: center;
  align-self: flex-start;
  object-fit: contain;
}
.homePage > .HPDidYouSeeContainer > .HPDYSTC-grid > .HPDYSTC-info {
  padding: 0 5.75rem 0 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.homePage > .HPDidYouSeeContainer > .HPDYSTC-grid > .HPDYSTC-info > p {
  color: white;
}
.homePage > .HPDidYouSeeContainer > .HPDYSTC-grid > .HPDYSTC-info > .invertedColorsButton {
  margin: 0;
}
.homePage > .HPDidYouSeeContainer > .HPDYSTC-grid > .HPDYSTC-info > h2 {
  margin-top: 0;
}
.homePage .HPQuiz > .HPELHeading {
  font-size: 1.25rem;
  color: var(--primaryColor);
  margin: 0 0 1.5rem 0;
  text-align: center;
}
.homePage .HPQuiz > p {
  text-align: center;
  max-width: 95vw;
}
.homePage .HPMap {
  width: 100%;
}
.homePage .HPMap > div {
  width: 100%;
}

.gmap_canvas {
  height: 500px;
}

@media only screen and (max-width: 1179px) {
  .homePage {
    display: flex;
    flex-direction: column;
  }
  .homeVideoTextOverlay > .imprecoContainer {
    position: static !important;
    max-width: 80vw;
  }
  .homeVideoTextOverlay > h1 {
    font-size: 2rem !important;
  }
  .homeVideoTextOverlay > h2 {
    font-size: 1.15rem !important;
  }
  .HPDYSTC-grid {
    display: flex !important;
    flex-direction: column !important;
    padding: 2rem !important;
  }
  .HPDYSTC-grid > div {
    padding: 0 !important;
  }
  .HPDYSTC-grid > img {
    max-width: 100%;
    margin-top: 2rem !important;
  }
  .imageWithLinkContainer > p {
    text-align: center;
  }
  .HPContent > img {
    width: 100%;
    box-sizing: border-box;
    padding: 2rem !important;
  }
  .HPContent > .imageRow {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .HPQuiz {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .parallaxWrapper {
    justify-self: center;
    align-self: center;
  }
  .parallaxWrapper > .parallaxOverlay {
    margin-bottom: 0.5rem !important;
  }
  .parallaxWrapper > .parallaxOverlay > .invertedColorsButton {
    margin: 0.8rem 1.5rem !important;
  }
  .welcomeBanner {
    font-size: 1.3rem !important;
    height: 5rem;
  }
}
.languageFlag {
  width: 30px;
}

.languageButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 2px;
  height: 100%;
  width: 7rem;
  background: rgb(150, 150, 150);
  color: white;
  box-sizing: border-box;
  padding: 5px;
  font-size: 0.85rem;
  z-index: 400;
  font-weight: 500;
  cursor: pointer;
}
.languageButton:hover {
  background: rgb(200, 200, 200);
}
.languageButton > img {
  padding-right: 0.3rem;
}
@media only screen and (max-width: 1179px) {
  .languageButton {
    font-size: 0.6rem;
  }
}

.languagePicker {
  position: relative;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 401;
  display: flex;
  flex-direction: column;
}
.languagePicker > .languageSelectionBackground {
  position: absolute;
  top: 100%;
  z-index: 401;
  padding-top: 5px;
}

@media only screen and (max-width: 1179px) {
  .languageButton {
    width: 6rem;
  }
}
.languageIcon {
  height: 1.7rem;
  box-sizing: border-box;
  color: white;
}

.PG-container {
  width: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, 280px);
}
.PG-container > .PG-photo {
  width: 270px;
  height: 270px;
  object-fit: cover;
  padding: 5px;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: pointer;
}
.PG-container > .PG-photo:hover {
  padding: 0;
}
.PG-container > .PG-photo > img {
  width: 100%;
  height: 100%;
}
.PG-container > .PG-maximizedPictureContainer {
  position: fixed;
  top: 0;
  z-index: 150;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  padding: 3.5rem 1rem 1rem 1rem;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
}
.PG-container > .PG-maximizedPictureContainer > .PG-maximizedPicture {
  width: 80vw;
  height: 100%;
  object-fit: contain;
  margin: 10px;
}
.PG-container > .PG-maximizedPictureContainer > .PG-leftArrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 1rem;
  height: 100px;
  color: white;
  cursor: pointer;
}
.PG-container > .PG-maximizedPictureContainer > .PG-rightArrow {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2rem;
  color: white;
  cursor: pointer;
}
.PG-container > .PG-maximizedPictureContainer > .PG-x-button {
  position: fixed;
  margin: 3rem;
  top: 0;
  right: 0;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 1179px) {
  .PG-container {
    width: 90vw;
    grid-template-columns: 1fr 1fr;
  }
  .PG-container > * {
    width: 100% !important;
    height: 45vw !important;
    padding: 1rem;
    box-sizing: border-box;
  }
  .PG-container > .PG-maximizedPictureContainer {
    width: 100vw !important;
    height: 100vh !important;
    padding-top: 3.5rem !important;
  }
  .PG-container > .PG-maximizedPictureContainer > .PG-leftArrow,
.PG-container > .PG-maximizedPictureContainer .PG-rightArrow {
    width: 3rem;
    height: 3rem;
  }
  .PG-container > .PG-maximizedPictureContainer > .PG-leftArrow > *,
.PG-container > .PG-maximizedPictureContainer .PG-rightArrow > * {
    font-size: 3rem;
  }
  .PG-container > .PG-maximizedPictureContainer > img {
    width: 100vw !important;
  }
  .PG-container > .PG-maximizedPictureContainer > .PG-x-button {
    font-size: 0.7rem !important;
    margin: 3rem 0.5rem 0 0 !important;
  }
  .PG-container > .PG-maximizedPictureContainer > .PG-leftArrow {
    left: 0;
  }
  .PG-container > .PG-maximizedPictureContainer > .PG-rightArrow {
    right: 0;
  }
}
.VG-container {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 350px);
}
.VG-container > .VG-videoThumbContainer {
  position: relative;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VG-container > .VG-videoThumbContainer > .VG-video {
  width: 100%;
  object-fit: cover;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
}
.VG-container > .VG-videoThumbContainer > .VG-video:hover {
  padding: 0;
  -webkit-box-shadow: 0px 6px 23px 9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 23px 9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 23px 9px rgba(0, 0, 0, 0.75);
}
.VG-container > .VG-videoThumbContainer > .VG-playVideoPictureOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: no-repeat center;
  background-size: 70px 70px;
  pointer-events: none;
}
.VG-container > .VG-maximizedVideoContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  padding: 10rem 0 1.25rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  z-index: 120;
}
.VG-container > .VG-maximizedVideoContainer > .VG-maximizedVideo {
  object-fit: contain;
  max-height: 70vh;
  max-width: 80vw;
  margin: 10px;
}
.VG-container > .VG-maximizedVideoContainer > .VG-maximizedVideo > .VG-maximizedVideoSource {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.VG-container > .VG-maximizedVideoContainer > .VG-x-button {
  position: fixed;
  width: 100px;
  height: 100px;
  top: 25vh;
  right: 10px;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 1179px) {
  .VG-container {
    display: flex !important;
    flex-direction: column;
  }
  .VG-maximizedVideoContainer {
    padding: 0 !important;
  }
  .VG-maximizedVideoContainer > .VG-maximizedVideo {
    max-width: unset !important;
    max-height: unset !important;
    width: 100vw;
    margin-top: 2.5rem !important;
    height: calc(100vh - 2.5rem);
  }
  .VG-x-button {
    font-size: 0.7rem;
    right: 0.6rem !important;
    top: 2.5rem !important;
    height: unset !important;
    width: unset !important;
  }
}
#html5-videos {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
}
#html5-videos > li {
  width: 100%;
  height: 100%;
}
#html5-videos > li > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#html5-videos > li > img:hover {
  transform: scale(1.05);
}

.serviceInfoBullet {
  margin: 5px;
}

.rulesBackground {
  background-color: #e8faff;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 5rem 2rem 5rem;
  margin: 0;
  max-width: 1180px;
}

.SIImageWithBackground {
  position: relative;
  left: -0.75rem;
  margin: 3.75rem 0 7rem 0;
}
.SIImageWithBackground .SIImageBackground {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: -5;
  width: 629px;
  height: 355px;
  opacity: 0.6;
  background: linear-gradient(260deg, rgb(76, 166, 193) 0%, rgb(38, 83, 97) 100%);
}

@media only screen and (max-width: 1179px) {
  .rulesBackground {
    padding: 0 0.5rem 0 0;
  }
  .rulesBackground > ul {
    padding-left: 2rem;
  }
  .rulesBackground > h2 {
    padding: 1rem 0 0 36px;
  }
  .SIImageWithBackground {
    width: 90%;
    left: -0.4rem !important;
  }
  .SIImageWithBackground > * {
    top: 0.8rem !important;
    left: 0.8rem !important;
    width: 100% !important;
    height: 100% !important;
  }
}
.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  font-family: "Roboto";
  font-size: 1.05rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.05px;
  text-align: left;
  color: #252525;
}

.GIgradientBackground {
  width: 1180px;
  max-width: 100vw;
  background-image: linear-gradient(90deg, rgb(64, 111, 125) 10%, rgb(76, 166, 193) 59%);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.GIImageArea {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
}

.GITextArea {
  width: 100%;
  height: 100%;
  padding: 50px 0 50px 50px;
  box-sizing: border-box;
}
.GITextArea > p,
.GITextArea h2 {
  color: white;
}
.GITextArea > p {
  font-family: "Roboto";
  font-size: 1.05rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.05px;
  text-align: left;
  color: #ffffff;
}
.GITextArea > h2 {
  margin-top: 0;
}

.GIWideElement {
  width: 1180px;
  max-width: 90vw;
}

@media only screen and (max-width: 1179px) {
  .GIgradientBackground {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .GIgradientBackground > .GITextArea {
    padding: unset;
  }
  .GIgradientBackground > div > img {
    width: 100%;
  }
}
.footerContainer {
  height: auto;
  padding: 0;
  padding-top: 3rem;
  width: 100%;
  margin: 0 auto;
  background-color: rgb(31, 143, 178);
  background-image: url("./footerBackground.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.footerContainer > .footerGrid {
  margin: auto;
  display: grid;
  grid-template-rows: auto 3rem 3.75rem;
  width: 1180px;
}
.footerContainer > .footerGrid > .footerInfo {
  width: 100%;
  border-top: 1px solid white;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto";
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  height: 100%;
  align-items: center;
}
.footerContainer > .footerGrid > .footerInfo > div > span {
  padding: 0 10px;
}
.footerContainer > .footerGrid > .footerInfo > div > a {
  color: white;
  text-decoration: none;
}
.footerContainer > .footerGrid > .footerInfo > div > span > a {
  color: white;
  text-decoration: none;
}
.footerContainer > .footerGrid > .footerLinks {
  display: grid;
  width: 100%;
  grid-template-columns: 34% 22% 22% 22%;
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn {
  padding: 0 15px;
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn > .footerSublist {
  margin-top: 0.5rem;
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn > .footerSublist > a {
  margin-left: 1rem;
}
@media only screen and (max-width: 800px) {
  .footerContainer > .footerGrid > .footerLinks > .footerColumn > .footerSublist > a {
    font-size: 1.2rem;
  }
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn > .footerSublist > .footerLink:hover {
  text-shadow: 0px 0px 6px white;
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn > p {
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: -0.05px;
  text-align: left;
  color: #ffffff;
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn > img {
  margin: 1em 0;
  width: 200px;
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn > h3,
.footerContainer > .footerGrid > .footerLinks > .footerColumn a > h3 {
  color: white;
  margin: 0.5em 0;
  margin-inline-end: 0;
  padding-bottom: 0;
}
@media only screen and (max-width: 800px) {
  .footerContainer > .footerGrid > .footerLinks > .footerColumn > h3,
.footerContainer > .footerGrid > .footerLinks > .footerColumn a > h3 {
    font-size: 1.4rem;
    width: 100%;
    height: 46px;
    line-height: 46px;
  }
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn > span,
.footerContainer > .footerGrid > .footerLinks > .footerColumn a {
  cursor: pointer;
  text-decoration: none;
  font-family: "Roboto";
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  word-wrap: break-word;
  flex-wrap: wrap;
  line-height: 1.87;
  letter-spacing: -0.05px;
  text-align: left;
  color: #ffffff;
  display: block;
}
@media only screen and (max-width: 800px) {
  .footerContainer > .footerGrid > .footerLinks > .footerColumn > span,
.footerContainer > .footerGrid > .footerLinks > .footerColumn a {
    font-size: 1.2rem;
    padding: 0;
    width: 100%;
    line-height: 46.5px;
  }
}
.footerContainer > .footerGrid > .footerLinks > .footerColumn > .footerLink:hover,
.footerContainer > .footerGrid > .footerLinks > .footerColumn a:hover {
  text-shadow: 0px 0px 6px white;
}

.fesbFooterIconRow {
  display: flex !important;
  width: 100%;
  justify-content: center;
}
.fesbFooterIconRow > .sAppIcon {
  text-decoration: none;
  width: unset !important;
}
.fesbFooterIconRow > .sAppIcon > i {
  cursor: pointer;
  margin: 8px 16px 8px 0;
  color: #4ca6c1;
  background-color: white;
  height: 32px;
  font-size: 1.25rem;
  width: 32px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 16px;
}
@media only screen and (max-width: 1179px) {
  .fesbFooterIconRow > .sAppIcon > i {
    margin: 8px 12px 12px 0px;
  }
}

@media only screen and (max-width: 1179px) {
  .footerContainer {
    height: unset;
  }
  .footerContainer > .footerGrid {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;
  }
  .footerContainer > .footerGrid > .footerLinks {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .footerContainer > .footerGrid > .footerLinks > .footerColumn {
    display: flex;
    flex-direction: column;
  }
  .footerContainer > .footerGrid > .footerLinks > .footerColumn > img {
    align-self: center;
  }
  .footerContainer > .footerGrid > .footerInfo {
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 1rem;
  }
  .footerContainer > .footerGrid > .footerInfo > div {
    display: flex;
    flex-direction: column;
  }
  .footerContainer > .footerGrid > .footerInfo > div > a {
    color: white;
  }
  .footerContainer > .footerGrid > .footerInfo > div > span {
    padding: 0 !important;
  }
}
.logoRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-around;
  height: 100px;
}
.logoRow > a > * {
  height: 100px;
}
@media only screen and (max-width: 1179px) {
  .logoRow {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .logoRow > a {
    width: auto !important;
  }
  .logoRow > a > * {
    height: 100px;
  }
}

.SDCountyLink {
  display: grid !important;
  width: 200px !important;
  height: 100px !important;
  grid-template-columns: 86px 110px;
  justify-content: center;
  align-items: center;
}
.SDCountyLink > .countyTitle {
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  height: auto;
}
@media only screen and (max-width: 1179px) {
  .SDCountyLink {
    width: 100% !important;
    grid-template-columns: 100px calc(90vw - 100px);
  }
  .SDCountyLink > .countyTitle {
    margin: 0.5rem;
  }
}

.pageHeader {
  width: 100%;
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: center;
}
.pageHeader > p {
  width: 90%;
  font-family: "Roboto";
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  margin-bottom: 0;
}
.pageHeader > img {
  margin: 2rem 0;
}

@media only screen and (max-width: 1179px) {
  .pageHeader > p {
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
  }
}
.natHerContent {
  max-width: 90vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.natHerContent > img {
  margin: 30px 0;
  width: 100%;
}
.natHerContent > .natHerImages {
  width: 100%;
  max-width: 90vw;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 370px);
  align-items: center;
  margin: 2rem 0 0 0;
}
@media only screen and (max-width: 1240px) {
  .natHerContent > .natHerImages {
    grid-template-columns: repeat(auto-fill, 32%);
  }
}
@media only screen and (max-width: 900px) {
  .natHerContent > .natHerImages {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
}
.natHerContent > .natHerImages > * {
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 370px;
  margin: 0 0 1rem 0;
}

@media only screen and (max-width: 1179px) {
  .naturalHeritage > h2 {
    text-align: center;
  }
}
.culHisImages {
  display: grid;
  width: 100%;
  max-width: 95vw;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 370px);
  margin: 3rem 0 0;
}
@media only screen and (max-width: 1240px) {
  .culHisImages {
    grid-template-columns: repeat(auto-fill, 32%);
  }
}
@media only screen and (max-width: 900px) {
  .culHisImages {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
  }
}
.culHisImages > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 370px;
}
.culHisImages > div > img {
  margin-bottom: 10px;
}

.culHistHeritageContent {
  width: 100%;
}

.culHistImage {
  width: 100%;
  max-width: 370px !important;
  margin: 0 auto;
}

.imageWithCaption {
  max-width: 100%;
  position: relative;
}
.imageWithCaption > p {
  font-size: 0.85rem;
  margin: 0;
  max-width: 100%;
  box-sizing: border-box;
}
.imageWithCaption > img {
  width: 100%;
  object-fit: cover;
}
.imageWithCaption > .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  z-index: 102;
  margin: 0.5rem;
}

.pictureLeft > div > .IWC-caption {
  margin-left: 0 !important;
}

.geoPaleoImages {
  display: grid;
  width: 100%;
  max-width: 95vw;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 370px);
  align-items: center;
}
@media only screen and (max-width: 1240px) {
  .geoPaleoImages {
    grid-template-columns: repeat(auto-fill, 32%);
  }
}
@media only screen and (max-width: 900px) {
  .geoPaleoImages {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
}
.geoPaleoImages > div {
  max-width: 370px;
}
.geoPaleoImages > .imageWithCaption {
  margin-bottom: 1rem;
  height: 100%;
}

.geoPaleo > div {
  width: 100%;
  max-width: 90vw;
}

.GPHSubtitle {
  margin: 3rem 0 1rem;
}
@media only screen and (max-width: 1240px) {
  .GPHSubtitle {
    margin: 2rem 0 1rem;
  }
}

.SFALImages {
  display: grid;
  width: 100%;
  margin: 3rem 0;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 550px);
}
.SFALImages > div,
.SFALImages img {
  margin: 1rem 0;
}
.SFALImages > div > img,
.SFALImages img > img {
  margin: 0;
}

.SFALText > h2 {
  margin-top: 0;
}

@media only screen and (max-width: 1240px) {
  .SFALImages {
    display: flex;
    flex-direction: column;
    width: 550px;
    grid-template-columns: unset;
  }
  .SFALImages > * {
    margin: 1rem 0 !important;
  }
}
@media only screen and (max-width: 680px) {
  .SFALImages {
    width: 100%;
  }
  .SFALImages > * {
    width: 100%;
  }
  .SFALImages > * > * {
    width: 100%;
  }
}
.SFImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invertedColorsButton {
  padding: 0.8rem 1.6rem;
  cursor: pointer;
  text-align: center;
  margin: 0.5rem;
  text-decoration: none;
}

.imageWithLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageWithLinkContainer > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.imageWithLinkContainer > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imageWithLinkContainer > div > p {
  margin: 1rem;
  font-weight: bold;
  text-align: center;
}
.imageWithLinkContainer > div > div {
  font-weight: normal;
}

.parallaxContainer {
  overflow: hidden;
}
.parallaxContainer > img {
  width: 100%;
}

.DYSTCustomHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DYSTCustomHeader > p {
  text-align: center;
}
.DYSTCustomHeader > h3 {
  font-size: 1.45rem;
}

.DYSTGrid {
  display: grid;
  height: 100%;
  width: calc(100vw - 6rem);
  justify-content: center;
  max-width: calc(1440px + 2rem);
  grid-template-columns: repeat(auto-fill, 433px);
}
.DYSTGrid > .imageWrapper {
  margin: 0.5rem;
  cursor: pointer;
}

.DYSTFormInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 2rem 0;
}
.DYSTFormInfoWrapper > p,
.DYSTFormInfoWrapper h2 {
  text-align: center;
}

@media only screen and (max-width: 1179px) {
  .DYSTGrid {
    grid-template-columns: minmax(10px, 430px);
  }
  .DYSTGrid > * {
    margin: 1rem 0rem !important;
  }
}
.navLogo {
  height: 4.5rem;
  width: 4.5rem;
  margin-left: 0.5rem;
  transition: height 0.5s, width 0.5s, margin-left 0.5s;
  cursor: pointer;
}

.dropDownButton {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(100, 100, 100, 0.9);
  text-decoration: none;
}
.dropDownButton > i {
  margin-left: 10px;
}
.dropDownButton:hover {
  background: rgba(170, 170, 170, 0.9);
}

.dropDown {
  max-width: 300px;
  min-width: 180px;
  transition: height 0.5s;
}

.navBar {
  z-index: 111;
  background-color: rgba(20, 66, 80, 0.7333333333);
  height: 5.5rem;
  width: calc(100% - 6rem);
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  top: 3.5rem;
  margin: 0 3rem 1rem 3rem;
  transition: margin 0.5s, height 0.5s, width 0.5s, top 0.5s;
}
.navBar > a {
  height: 100%;
  display: flex;
  align-items: center;
}
.navBar > .imprecoContainer {
  position: absolute;
  top: 6rem;
  right: 0;
  opacity: 1;
  transition: height 0.5s, width 0.5s, opacity 0.5s;
}
.navBar > .imprecoContainer > img {
  cursor: pointer;
}
.navBar > .hamburgerButton {
  display: none;
  cursor: pointer;
}
.navBar > .dropDown {
  flex-direction: row;
  background: none;
  margin: 0;
  height: 100%;
  max-width: unset;
  min-width: unset;
  z-index: 111;
}
.navBar > .dropDown > .dropDownContainer {
  margin: 0;
  z-index: 111;
}
.navBar > .dropDown > .dropDownContainer > .ddchild {
  left: 0;
  padding-top: 10px;
  top: 100%;
}
.navBar > .dropDown > .dropDownContainer > .dropDownButton {
  color: white;
  height: 100%;
  background: unset;
  padding: 10px 20px;
  font-weight: 700;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}
.navBar > .dropDown > .dropDownContainer > .dropDownButton.active {
  background: rgba(255, 255, 255, 0.3);
}
.navBar > .dropDown > .dropDownContainer > .dropDownButton:hover {
  background: rgba(255, 255, 255, 0.3);
}

.sticky {
  height: 3.3rem;
  width: 100%;
  top: 2.5rem;
  margin: 0;
  transition: margin 0.5s, height 0.5s, width 0.5s, top 0.5s;
}
.sticky > a > .navLogo {
  height: 2.7rem;
  width: 2.7rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
.sticky > .imprecoContainer {
  opacity: 0;
}

@media only screen and (max-width: 1179px) {
  .dropDownContainer {
    background: rgb(100, 100, 100);
  }
  .dropDownButton {
    background-color: unset !important;
    text-align: center;
  }
  .dropDownButton :active {
    background-color: unset !important;
  }
  .dropDownButton :hover {
    background-color: unset !important;
  }
  .navBar {
    height: 3.3rem;
    width: 85vw;
    width: 100vw;
    margin: 0;
    top: 2.5rem;
    background: #144250;
    justify-content: space-between;
  }
  .navBar > a > .navLogo {
    height: 2.75rem !important;
    width: 2.75rem;
  }
  .navBar > .imprecoContainer {
    position: absolute;
    top: 3.3rem;
    margin-top: 1rem;
    width: 100vw;
    display: flex;
    align-items: center;
    height: unset;
    justify-content: center;
  }
  .navBar > .imprecoContainer > img {
    max-width: 70vw;
    position: relative;
    top: 0;
    cursor: pointer;
  }
  .navBar > * {
    font-size: 1.35rem;
  }
  .navBar > .hamburgerButton {
    display: inline-block;
    padding: 1rem;
    height: 100%;
  }
  .navBar > .dropDown {
    max-height: 80vh;
    position: absolute;
    height: 0;
    overflow: hidden;
    overflow-y: scroll;
    top: 3.3rem;
    flex-direction: column;
    background-color: #1f8fb2 !important;
    width: 100%;
  }
  .navBar > .dropDown > .dropDownContainer {
    background: #1f8fb2;
    width: 100%;
    margin: 0;
  }
  .navBar > .dropDown > .dropDownContainer > .dropDown {
    position: static;
    max-width: unset;
    left: unset;
    top: unset;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .navBar > .dropDown > .dropDownContainer > .dropDown > .dropDownContainer {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navBar > .dropDown > .dropDownContainer > .dropDown > .dropDownContainer > .dropDownButton {
    width: auto;
    margin: 0;
  }
  .navBar > .dropDown > .dropDownContainer > .dropDown > .dropDownContainer > .dropDown {
    position: static;
    max-width: unset;
    left: unset;
    top: unset;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .navBar > .dropDown > .dropDownContainer > .dropDown > .dropDownContainer > .dropDown > .dropDownContainer {
    width: 100%;
    margin: 0;
    display: flex;
    background-color: rgb(130, 130, 130);
    flex-direction: column;
    align-items: center;
  }
  .navBar > .dropDown > .dropDownContainer > .dropDown > .dropDownContainer > .dropDown > .dropDownContainer > .dropDownButton {
    width: auto;
    margin: 0;
  }
  .navBar > .expanded {
    height: auto;
  }
}
.dropDown {
  display: flex;
  flex-direction: column;
}
.dropDown > .dropDownContainer {
  position: relative;
}

.ddchild {
  position: absolute;
  left: 100%;
  top: 0;
  opacity: 0;
}

.divingBlueBackground {
  background-color: #e8faff;
  box-sizing: border-box;
  padding: 1.6rem;
}
.divingBlueBackground > * {
  margin: 0;
}

.divingContentUnit {
  margin-bottom: 1.5rem;
  width: 100%;
}
@media only screen and (max-width: 1000px) {
  .divingContentUnit {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .diving > p {
    margin-bottom: 0;
  }
  .diving > img {
    width: 100%;
  }
  .diving > .divingContentUnit > .pictureRight > img,
.diving .pictureLeft > img {
    margin-top: 1rem;
  }
  .divingBlueBackground {
    margin: 0 0 0 0 !important;
  }
}
.footpaths > .imageRow {
  display: grid;
  width: 100%;
  margin: calc(3rem - 1em) 0 0 0;
  max-width: 95vw;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, calc(550px + 2rem));
}
.footpaths > .imageRow > div,
.footpaths > .imageRow img {
  margin: 1rem;
}
.footpaths > .imageRow > div > img,
.footpaths > .imageRow img > img {
  margin: 0;
}

@media only screen and (max-width: 1179px) {
  .footpaths > .imageRow {
    display: flex !important;
    flex-direction: column !important;
  }
  .footpaths > .imageRow > .imageWithCaption {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.beaches > .imageRow {
  display: grid;
  width: 100%;
  margin: calc(3rem - 1em) 0 0 0;
  max-width: 95vw;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, calc(500px + 2rem));
}
@media only screen and (max-width: 1180px) {
  .beaches > .imageRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
}
.beaches > .imageRow > div,
.beaches > .imageRow img {
  margin: 1rem;
  height: unset;
  max-width: 90vw;
}
.beaches > .imageRow > div > img,
.beaches > .imageRow img > img {
  margin: 0;
  height: unset;
}

.sailing > .SailingImageWithBackground {
  margin-top: calc(3rem - 1em);
  position: relative;
  left: -0.75rem;
}
.sailing > .SailingImageWithBackground > .SailingImageBackground {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: -5;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: linear-gradient(260deg, rgb(76, 166, 193) 0%, rgb(38, 83, 97) 100%);
}

@media only screen and (max-width: 1179px) {
  .SailingImageWithBackground {
    width: 90% !important;
  }
  .SailingImageWithBackground > * {
    width: 100%;
  }
  .sailing > img {
    height: unset !important;
  }
  .sailing > .imageWrapper {
    margin: 1rem 0 !important;
  }
}
.DYSTModalContainer {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1111;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DYSTModalContainer > .DYSTModal {
  max-height: 95%;
  width: 60vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.05px;
  text-align: left;
  padding: 4rem;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.53);
  border: solid 1px #4ca6c1;
  background-color: #ffffff;
}
.DYSTModalContainer > .DYSTModal > .divider {
  width: 8vw;
  height: 0;
  margin: calc(2rem - 1em) 0;
  border-top: 1px solid #4ca6c1;
}
.DYSTModalContainer > .DYSTModal > .closeDYSTModal {
  position: absolute;
  right: 2rem;
  top: 2rem;
  font-size: 2rem;
  cursor: pointer;
}
.DYSTModalContainer > .DYSTModal > h1 {
  font-size: 1.3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #1a1a1a;
}

@media only screen and (max-width: 1179px) {
  .DYSTModal {
    width: 90% !important;
    box-sizing: border-box;
    padding: 2rem 1rem 1rem 1rem !important;
    height: 95%;
    display: grid !important;
    align-items: center !important;
    justify-content: center !important;
    grid-template-rows: 15% 0px 30% 55%;
  }
  .DYSTModal > .DYSTModalImageContainer {
    width: 100%;
    height: 100%;
  }
  .DYSTModal > .DYSTModalImageContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: unset !important;
    max-height: unset !important;
  }
  .DYSTModal > h1 {
    text-align: center !important;
    font-size: 1.1rem !important;
    font-weight: bold !important;
  }
  .DYSTModal > p {
    height: calc(100% - 1rem);
    padding: 1rem 0;
    margin-top: 1rem;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  .DYSTModal > .divider {
    width: 25% !important;
    margin: auto !important;
  }
  .DYSTModal > .closeDYSTModal {
    top: 1rem !important;
    right: 1rem !important;
  }
}
.imageWrapper {
  position: relative;
}
.imageWrapper > .DYSTImageBottomShadow {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 25%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65));
}
.imageWrapper > .DYSTImageInfo {
  position: absolute;
  top: 13px;
  right: 13px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
  margin: 3rem;
  z-index: -2;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 96px;
  height: 96px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinnerContainer {
  z-index: 5;
}

.SpCBackground {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: -4;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgb(120, 120, 120) 0%, rgb(170, 170, 170) 100%);
}

.pageIsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 8rem;
  padding: 2rem;
}

@media only screen and (max-width: 1179px) {
  .pageIsLoading {
    width: 100vw;
    max-width: 100%;
    box-sizing: border-box;
    margin-top: 5.8rem;
  }
}
.clickableMapContainer {
  width: 1000px;
  max-width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}
.clickableMapContainer > .clickableMapWrapper {
  position: relative;
}
.clickableMapContainer > .CMOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.3333333333);
}
.clickableMapContainer > .CMOverlay > h3 {
  color: white;
  text-align: center;
}
.clickableMapContainer > .visible {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickableMapContainer > .CMZoomControls {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.5rem;
}
.clickableMapContainer > .CMZoomControls > .CMZoomButton {
  width: 3.5rem;
  height: 3.5rem;
  background: radial-gradient(var(--primaryColor), var(--primaryColorLight));
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickableMapContainer > .CMZoomControls > .CMZoomButton > i {
  font-size: 1.7rem;
  color: white;
}
.clickableMapContainer > .CMZoomControls > .CMZoomInButton {
  margin-bottom: 0.5rem;
}
.DYSTFormContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100%;
}
.DYSTFormContainer > .DYSTFormInputs {
  display: grid;
  max-width: 100%;
  width: 50%;
  min-width: 400px;
  grid-template-columns: 100%;
  margin-bottom: 2rem;
}
.DYSTFormContainer > .DYSTFormInputs > .inputAndLabelContainer {
  margin: 0.8rem;
  display: flex;
  flex-direction: column;
}
.DYSTFormContainer > .DYSTFormInputs > .inputAndLabelContainer > label {
  padding-bottom: 0.3rem;
}
.DYSTFormContainer > div > #clickableMab {
  margin: 0.5rem 0 2rem 0;
}
.DYSTFormContainer > .DYSTHiddenPhotoInput {
  visibility: hidden;
  position: fixed;
  top: 0;
}
.DYSTFormContainer > .DYSTPrivacyContainer {
  width: 100%;
  max-width: 800px;
  display: flex;
  margin-bottom: 3rem;
  justify-content: flex-start;
  align-items: center;
}
.DYSTFormContainer > .DYSTPrivacyContainer > #privacyCheck {
  margin: 2.5vw;
}
.DYSTFormContainer > .DYSTPrivacyContainer > label {
  width: 90%;
}
.DYSTFormContainer > .DYSTPrivacyContainer > label > a {
  text-decoration: none;
  color: var(--primaryColor);
}
.DYSTFormContainer > .invertedColorsButton {
  margin: 0 auto;
  width: 180px;
  max-width: 50vw;
}

.DYSTInput {
  height: 2.6rem;
  cursor: pointer;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px #4ca6c1;
  background-color: #ffffff;
}

@media only screen and (max-width: 800px) {
  .DYSTFormInputs {
    display: flex !important;
    flex-direction: column;
    min-width: unset !important;
    width: 100% !important;
  }
  .DYSTFormInputs > div {
    margin: 0.8rem 0 !important;
  }
}
.privacyPage {
  margin-bottom: 5.5rem;
}

.noPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: center no-repeat cover;
  margin-top: 0 !important;
  max-width: unset !important;
  height: 50vw;
  min-height: 50vh;
  width: 100vw;
}
.noPage > .messageContainer {
  height: 100px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noPage > .messageContainer .textWithMargin {
  display: inline-block;
  width: auto;
  text-align: center;
}
.noPage > .messageContainer .textWithMargin > * {
  display: inline-block;
  font-weight: 700;
  color: white;
  font-size: 1.25rem;
  margin: 0;
}
.noPage > .messageContainer .textWithMargin > .noPageLink {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.5rem;
  color: var(--primaryColorLight);
}

.publications {
  margin-bottom: 5.5rem;
}
.publications > p {
  text-align: start;
  width: 100%;
}
.publications > h1 {
  width: 100%;
  text-align: left;
}
.publications > .publicationsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
}
.publications > ul > li > a {
  background-color: var(--primaryColor);
  color: white;
  padding: 0.3rem;
  text-decoration: none;
  line-height: 2rem;
}
.publications > ul > li > .divider {
  width: 30vw;
  height: 0;
  margin: 0.2rem 0 calc(3rem - 1em) 0;
  border-top: 1px solid #4ca6c1;
}

.hospitality {
  align-items: flex-start;
  margin-bottom: 5.5rem;
}

.hospitality_partners {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
}
@media only screen and (max-width: 915px) {
  .hospitality_partners {
    flex-direction: column;
    gap: unset;
  }
}
.hospitality_partners > div {
  flex: 50%;
}
@media only screen and (max-width: 915px) {
  .hospitality_partners > div {
    flex: none;
  }
}
.hospitality_partners > div > a {
  line-height: 2rem;
}

.vineyards {
  margin-bottom: 5.5rem;
  align-items: flex-start;
}

@media only screen and (max-width: 1179px) {
  .touristMap > h1 {
    font-size: 1.4rem;
  }
}
.touristMap > img {
  width: 90vw;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.aboutUs {
  margin-bottom: 5.5rem;
  align-items: flex-start;
}
.aboutUs > h2 {
  text-align: left;
}

.contact {
  margin-bottom: 5.5rem;
  align-items: flex-start;
}
.contact > h2 {
  text-align: left;
}
.contact > p {
  margin: 0;
}

.botanicGarden {
  margin-bottom: 5.5rem;
  align-items: flex-start;
}

.markerLabel {
  background: white;
  padding: 5px;
  border-radius: 5px;
}

.mapInfoWindow {
  display: flex;
  flex-direction: column;
}
.mapInfoWindow > h1 {
  margin: 0.5rem;
}
.mapInfoWindow > p {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.mapInfoWindow > p > span {
  margin: 0 0.5rem;
}

.dateSelectionRow {
  display: flex;
  flex-direction: row;
  max-width: 250px;
  width: auto;
  justify-content: space-between;
  margin: 0.5rem 0;
  align-items: center;
}
.dateSelectionRow > label {
  font-weight: bold;
}
.dateSelectionRow > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dateSelectionRow > div > i {
  cursor: pointer;
  margin-left: 0.5rem;
}

.filterSelection {
  border: 1px solid black;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  margin: 1rem auto;
  max-width: 1000px;
  box-sizing: border-box;
}
.filterSelection > .seenThingSelection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.filterSelection > .seenThingSelection > .filterCheckBoxItem {
  flex-grow: 1;
  border-radius: 10px;
  padding: 5px;
  justify-content: center;
  margin: 0.2rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(20, 66, 80, 0.7333333333);
  border: 2px solid rgba(20, 66, 80, 0.7333333333);
  background: white;
  cursor: pointer;
}
.filterSelection > .seenThingSelection > .filterCheckBoxItem.selected {
  color: white;
  background: rgba(20, 66, 80, 0.7333333333);
  font-weight: bold;
}
.filterSelection > .seenThingSelection > .filterCheckBoxItem:hover {
  color: white;
  background: rgba(20, 66, 80, 0.7333333333);
}
.filterSelection > .seenThingSelection > .filterCheckBoxItem > p {
  margin: 0;
  color: inherit;
  font-weight: inherit;
}

.colorLegend {
  width: 15px;
  height: 15px;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid black;
}

.imprecoDataTable {
  align-items: center;
  padding: 10px;
}
.imprecoDataTable > div {
  padding: 5px;
}

.imprecoButtonRow {
  display: flex;
  margin: 25px 0;
  flex-direction: row;
  flex-wrap: wrap;
}
.imprecoButtonRow > * {
  box-sizing: border-box;
  margin-left: 0;
}

.imprecoLogoRow {
  color: white;
  background: linear-gradient(-90deg, rgb(76, 166, 193) 0%, rgb(38, 83, 97) 70%);
  display: grid;
  grid-template-columns: 360px auto;
  align-items: center;
  justify-content: center;
  position: relative;
}
.imprecoLogoRow > a {
  width: 300px;
  height: 200px;
  align-self: center;
  justify-self: center;
}

.imprecoParagraph {
  text-align: justify;
}

.imprecoSocialMediaContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
  display: flex;
  flex-direction: row;
}
.imprecoSocialMediaContainer > a > img {
  height: 2rem;
  margin: 0.3rem;
}

#newsOverview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 60px;
  gap: 60px;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  #newsOverview {
    grid-template-columns: 1fr;
  }
}
#newsOverview hr {
  display: none;
  margin: 20px;
}
@media only screen and (max-width: 900px) {
  #newsOverview hr {
    display: block;
  }
}
#newsOverview .card {
  color: black;
  transition: 200ms ease;
}
#newsOverview .card_image {
  display: flex;
  max-height: 300px;
  width: auto;
  overflow: hidden;
}
#newsOverview .card_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#newsOverview .card_container {
  height: 700px;
  position: relative;
  overflow: hidden;
  padding: 20px;
}
#newsOverview .card_overlay {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-image: linear-gradient(0deg, white 25%, transparent 100%);
  position: absolute;
  height: 300px;
  bottom: 0;
  left: 0;
  right: 0;
}
#newsOverview .card_text {
  margin: 40px 0;
}
#newsOverview .card_text p {
  margin: 10px 0 !important;
}
#newsOverview .card_date {
  margin: 20px 0;
}
#newsOverview .card:hover {
  background-color: rgba(224, 224, 224, 0.406);
}
#newsOverview .card:hover .card_overlay {
  background-image: linear-gradient(0deg, rgb(224, 224, 224) 25%, transparent 100%);
}

#newsPage {
  margin-bottom: 120px;
}
#newsPage .page {
  color: black;
}
#newsPage .page h2 {
  margin: 40px 0;
}
#newsPage .page_image {
  display: flex;
  height: 100%;
  width: 100%;
}
#newsPage .page_image > img {
  width: 100%;
  height: 100%;
}
#newsPage .page_text p {
  margin: 10px 0 !important;
}
#newsPage .page_date {
  align-self: flex-end;
  margin: 20px;
}