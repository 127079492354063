.pageContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}
p {
    font-family: 'Roboto';
    font-size: 1.05rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: -0.05px;
    text-align: left;
    color: #252525;
}
.GIgradientBackground {
    width: 1180px;
    max-width: 100vw;
    background-image: linear-gradient(90deg, rgba(64, 111, 125, 1) 10%, rgba(76, 166, 193, 1) 59%);
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.GIImageArea {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
}
.GITextArea {
    width: 100%;
    height: 100%;
    padding: 50px 0 50px 50px;
    box-sizing: border-box;
    & > p,
    h2 {
        color: white;
    }
    & > p {
        font-family: 'Roboto';
        font-size: 1.05rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: -0.05px;
        text-align: left;
        color: #ffffff;
    }
    & > h2 {
        margin-top: 0;
    }
}
.GIWideElement {
    width: 1180px;
    max-width: 90vw;
}
@media only screen and (max-width: 1179px) {
    .GIgradientBackground {
        width: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        & > .GITextArea {
            padding: unset;
        }
        & > div > img {
            width: 100%;
        }
    }
}
