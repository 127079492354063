.pageHeader {
    width: 100%;
    display: flex;
    margin: 0;
    flex-direction: column;
    align-items: center;
    & > p {
        width: 90%;
        font-family: 'Roboto';
        font-size: 1.25rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: center;
        color: #252525;
        margin-bottom: 0;
    }
    & > img {
        margin: 2rem 0;
    }
}

@media only screen and (max-width: 1179px) {
    .pageHeader > p {
        font-size: 1rem;
        font-weight: 600;
        line-height: normal;
    }
}
