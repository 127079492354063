.sailing {
    & > .SailingImageWithBackground {
        margin-top: calc(3rem - 1em);
        position: relative;
        left: -0.75rem;
        & > .SailingImageBackground {
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            z-index: -5;
            width: 100%;
            height: 100%;
            opacity: 0.6;
            background: linear-gradient(260deg, rgba(76, 166, 193, 1) 0%, rgba(38, 83, 97, 1) 100%);
        }
    }
}

@media only screen and (max-width: 1179px) {
    .SailingImageWithBackground {
        width: 90% !important;
        & > * {
            width: 100%;
        }
    }
    .sailing {
        & > img {
            height: unset !important;
        }
        & > .imageWrapper {
            margin: 1rem 0 !important;
        }
    }
}
