.DYSTFormContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    width: 100%;
    & > .DYSTFormInputs {
        display: grid;
        max-width: 100%;
        width: 50%;
        min-width: 400px;
        grid-template-columns: 100%;
        margin-bottom: 2rem;
        & > .inputAndLabelContainer {
            margin: 0.8rem;
            display: flex;
            flex-direction: column;
            & > label {
                padding-bottom: 0.3rem;
            }
        }
    }
    & > div > #clickableMab {
        margin: 0.5rem 0 2rem 0;
    }
    & > .DYSTHiddenPhotoInput {
        visibility: hidden;
        position: fixed;
        top: 0;
    }
    & > .DYSTPrivacyContainer {
        width: 100%;
        max-width: 800px;
        display: flex;
        margin-bottom: 3rem;
        justify-content: flex-start;
        align-items: center;
        & > #privacyCheck {
            margin: 2.5vw;
        }
        & > label {
            width: 90%;
            > a {
                text-decoration: none;
                color: var(--primaryColor);
            }
        }
    }
    & > .invertedColorsButton {
        margin: 0 auto;
        width: 180px;
        max-width: 50vw;
    }
}
.DYSTInput {
    height: 2.6rem;
    cursor: pointer;
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #4ca6c1;
    background-color: #ffffff;
}

@media only screen and (max-width: 800px) {
    .DYSTFormInputs {
        display: flex !important;
        flex-direction: column;
        min-width: unset !important;
        width: 100% !important;
        & > div {
            margin: 0.8rem 0 !important;
        }
    }
}
