.footpaths {
    & > .imageRow {
        display: grid;
        width: 100%;
        margin: calc(3rem - 1em) 0 0 0;
        max-width: 95vw;
        justify-content: space-around;
        grid-template-columns: repeat(auto-fill, calc(550px + 2rem));
        & > div,
        img {
            margin: 1rem;
            & > img {
                margin: 0;
            }
        }
    }
}
@media only screen and (max-width: 1179px) {
    .footpaths {
        & > .imageRow {
            display: flex !important;
            flex-direction: column !important;
            & > .imageWithCaption {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
}
