$toolBarHeight: 2.5rem;
* {
  font-display: swap;
  font-family: "Roboto";
}
.page {
  --primaryColor: #3d798b;
  --primaryColorLight: #7ac1d6;
  margin: 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  top: $toolBarHeight;
  align-items: center;
  flex-direction: column;
}
.topHeading {
  margin-top: 0 !important;
}
.pageContent {
  margin-top: 7.5rem;
}

.pageContentImprecoSpecial {
  margin: 9.5rem 3rem 1rem;
}

.pageContent,
.pageContentImprecoSpecial {
  width: 90%;
  max-width: 1180px;
}
.pictureLeft {
  margin: 0;
  display: grid;
  grid-template-columns: 39% 61%;
  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
  }
  & > div > p {
    margin: 0 0 1rem 1rem;
    @media only screen and (max-width: 1000px) {
      margin-left: 0;
    }
  }
  & > img {
    width: 100%;
  }
}
.pictureRight {
  margin: 0;
  display: grid;
  grid-template-columns: 61% 39%;
  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
  & > div > p {
    margin: 0 1rem 1rem 0;
  }
  & > img,
  .imageWithCaption {
    height: auto;
    width: 100%;
  }
}
.noBottomMargin {
  margin-bottom: 0 !important;
}
.noMargin {
  margin: 0 !important;
}
h3 {
  font-size: 1.1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.05px;
  text-align: left;
}
html {
  font-size: 16px;
  overflow-x: hidden;
  width: 100%;
}
#root,
body {
  width: 100%;
}

@media only screen and (max-width: 1179px) {
  .pageContent {
    margin-top: 3.5rem;
  }
}
