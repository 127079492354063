.clickableMapContainer {
    width: 1000px;
    max-width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    & > .clickableMapWrapper {
        position: relative;
    }
    & > .CMOverlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        background: #00000055;
        & > h3 {
            color: white;
            text-align: center;
        }
    }
    & > .visible {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & > .CMZoomControls {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0.5rem;
        & > .CMZoomButton {
            width: 3.5rem;
            height: 3.5rem;
            background: radial-gradient(var(--primaryColor), var(--primaryColorLight));
            border-radius: 0.5rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            & > i {
                font-size: 1.7rem;
                color: white;
            }
        }
        & > .CMZoomInButton {
            margin-bottom: 0.5rem;
        }
        & > .CMZoomOutButton {
        }
    }
}
