.markerLabel {
    background: white;
    padding: 5px;
    border-radius: 5px;
}
.mapInfoWindow {
    display: flex;
    flex-direction: column;
    & > h1 {
        margin: 0.5rem;
    }
    & > p {
        margin: 0.5rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        & > span {
            margin: 0 0.5rem;
        }
    }
}
.dateSelectionRow {
    display: flex;
    flex-direction: row;
    max-width: 250px;
    width: auto;
    justify-content: space-between;
    margin: 0.5rem 0;
    align-items: center;
    & > label {
        font-weight: bold;
    }
    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > i {
            cursor: pointer;
            margin-left: 0.5rem;
        }
    }
}
